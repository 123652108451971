import React, { useState, useEffect } from "react";
import Logo from "../../../../assets/logo/Logo-Trans.png";
import image from "../../../../assets/logo/auth.png";
import { Link, useNavigate } from "react-router-dom";

import { BecomeaReseller } from "../../../../api/userRegestration/CollectionForm";
import { useWallet } from "../../../Wallet/Context/WalletContext";
export default function BecomeAReseller() {
  //
  const { togglereseller } = useWallet();

const navigate = useNavigate();
  //
  const handle = () =>{
    // console.log(loca)
    BecomeaReseller()
    .then((response) => {
      console.log("API Response:", response);
      togglereseller()

      navigate("/ResellerDashboard")
    })
    .catch((error) => {
      console.error("Error:", error);
    });

}


  return (
    <>
      <div className="lg:overflow-hidden">
        <div className="flex flex-row justify-start  ">
          <div className=" w-[100%] h-[100%] item-1 lg:h-screen lg:w-full bg-[#cb22290f] xs:min-h-screen  ">
            <div className="flex flex-col justify-center items-center mt-12 xs:mt-8 gap-[12px]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[134px] xs:h-[49px] bounce-in-top  "
              />
            </div>
            <div className="text">
              <h1 className="text-center font-all font-extrabold lg:text-[35px]  xs:text-[24px]  leading-[70px] text-[#000000] ">
                CONGARTULATIONS
              </h1>
              <p className=" text-center font-all xs:italic lg:w-[55rem] mx-auto lg:mt-[3rem] xs:mt-8  xs:text-[13px] xs:p-[13px] xs:text-center">
                CONGRATS YOU HAVE BECOME A INO RESELLER
              </p>
            </div>
            <div className="image relative top-[14px] drop-auth mx-auto lg:w-[23%]  w-[79%] bg-white rounded-[33px]">
              <img
                src={image}
                className="mx-ato m-auto lg:h-[21rem] image-coin  "
                alt=""
              />
            </div>

            <button onClick={handle} className=" flex justify-center fade-in-left bg-[#CB2229] text-white rounded-full font-all lg:p-[6px] xs:p-1  xs:mt-4 xs:w-[60%] lg:mt-[2.4rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out mx-auto">
              PROCCEED
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
