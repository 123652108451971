import React, { useContext } from "react";

import Messages from "./Messages";
import Input from "./Input";
import { ChatContext } from "../../../Context/ChatContext";

const Chat = () => {
  const { data } = useContext(ChatContext);

  return (
    // this is the header of the chat displaying the receiver image and name 
    <div className="chats justify-start gap-2">
      <div className="chatInfo ">
        {/* <img src={data.user?.photoURL} alt="" /> */}
        <img className=" w-10 h-10 rounded-full"  src={data.user?.photoURL}   alt="Rounded avatar"/>

        <span className="text-white font-all font-sembold">{data.user?.displayName}</span>
        <div className="chatIcons">
    
        </div>
      </div>
      <Messages />
      <Input/>
    </div>
  );
};

export default Chat;
