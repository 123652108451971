import React, { useContext, useState } from 'react';
import { collection, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from "react-router-dom";
import { ChatContext } from '../Context/ChatContext';
import { db } from '../firebase';
import { AuthContext } from '../Context/AuthContext';

function UserChatButton({ receiverId }) {
  const navigate = useNavigate();
  const { dispatch, user, setUser} = useContext(ChatContext);
  const { currentUser } = useContext(AuthContext);
  const [err, setErr] = useState(false);
 
  const handleSearch = async () => {
    const q = query(
      collection(db, "users"),
      where("uid", "==", receiverId)
    );

    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        console.log((doc.data()));
        console.log("user state updated with ",((doc.data())));
        setUser(doc.data());
      });
    } catch (err) {
      setErr(true);
    }
  };

  const handleSelect = async () => {
    if (!user) return;

    const combinedId =
      currentUser.uid > user.uid
        ? currentUser.uid + user.uid
        : user.uid + currentUser.uid;
    try {
      const res = await getDoc(doc(db, "chats", combinedId));

      if (!res.exists()) {
        await setDoc(doc(db, "chats", combinedId), { messages: [] });

        await updateDoc(doc(db, "userChats", currentUser.uid), {
          [combinedId + ".userInfo"]: {
            uid: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", user.uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });
      }
    } catch (err) {
      console.error("Error selecting user:", err);
    }

    setUser(null);
  };

  const navigateToChat = async () => {
    try {
      const db = getFirestore();
      const docRef = doc(db, "users", receiverId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const receiverUserData = docSnap.data();
        await handleSearch();
        // await handleSelect();
        dispatch({ type: "CHANGE_USER", payload: receiverUserData });
        navigate("/chat");
      } else {
        console.error('User document does not exist for specified ID');
      }
    } catch (error) {
      console.error('Error searching for user:', error);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-[1rem] mt-[1.1rem]">
        <button
          onClick={navigateToChat}
          className="bg-[#F8F8F8] border-[1px] border-[#CB2229] shadow-md lg:mx-auto p-[6px] w-[22rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229] hover:text-white"
        >
          Chat now
        </button>
      </div>
    </>
  );
}

export default UserChatButton;
