// Import necessary functions from Firebase and React
import { doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { db } from "../../../firebase";
import { AuthContext } from "../../../Context/AuthContext";
import { ChatContext } from "../../../Context/ChatContext";
// import {UserChatButton} from "../../../Widgets/UserChatButton"
// Define the Chats component
const Chats = () => {
  const [chats, setChats] = useState([]);
  const { receiverId } = useContext(ChatContext);
  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);
  //
  useEffect(() => {
    const getChats = () => {
      console.log("receier id",receiverId);
      const unsub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
        if (doc.exists()) {
          setChats(doc.data());
        } else {
          setChats([]);
        }
      });
      //
      return () => {
        unsub();
      };
    };
    //
    currentUser?.uid && getChats();
  }, [currentUser]);

  const handleSelect = (selectedUser) => {
    console.log("userjsk lorum ossd selected ",receiverId);
    console.log(currentUser.uid + receiverId);

    dispatch({ type: "CHANGE_USER", payload: selectedUser });
    // Navigate to the chat page (if needed)
    // navigate("/chat");
  };

  return (
    <div className="chats">
      {Object.entries(chats)
        ?.sort((a, b) => b[1].date - a[1].date)
        .map((chat) => (
          <div
            className="userChat"
            key={chat[0]}
            onClick={() => handleSelect(chat[1].userInfo)}
          >
            <img src={chat[1].userInfo.photoURL} alt="" />
            <div className="userChatInfo">
              <span>{chat[1].userInfo.displayName}</span>
              <p>{chat[1].lastMessage?.text}</p>

            </div>
          </div>
        ))}
    </div>
  );
};

export default Chats;
