import React, { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { IoIosArrowDown, IoIosArrowUp, IoIosSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import Spinner from "../component/Spinner";
import { useWallet } from "../pages/Wallet/Context/WalletContext";
import Validation_Toaster from "../Widgets/Validation_Toaster";

const CountrySelect = () => {
  const walletContext = useWallet();
  const navigate = useNavigate();
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showinputerror, setshowinputerror] = useState(false);
  const [searchCountry, setsearchcountry] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [countryState, setCountryState] = useState({
    loading: false,
    countries: [],
    errorMessage: "",
  });
  //
  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  //
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setIsOpen(false);
  };
  //
  const handleInputChange = (event) => {
    setshowinputerror(false);
    const inputValue = event.target.value;
    const numericInput = inputValue.replace(/[^0-9]/g, "");
    event.target.value = numericInput;
    const phoneNumberPattern = /^[0-9]{10}$/;
    if (!phoneNumberPattern.test(numericInput)) {
      event.target.setCustomValidity(
        "Please enter a valid 10-digit phone number."
      );
    } else {
      event.target.setCustomValidity("");
    }
    setInputValue(numericInput);
    const filtered = countryState.countries.filter((country) =>
      country.name.common.toLowerCase().includes(numericInput.toLowerCase())
    );

    setFilteredCountries(filtered);
  };
  //
  //
  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value.toLowerCase(); // Convert search input to lowercase
    setSearchInput(searchValue); // Update the search input state

    const filtered = countryState.countries.filter((country) =>
      country.name.common.toLowerCase().includes(searchValue)
    );

    setFilteredCountries(filtered);
  };
  //
  const handlePrint = async (e) => {
    e.preventDefault();
    setSpinner(true);
    if (inputValue === "") {
      console.log("validate state updated");
      setshowinputerror(true);
    } else {
      const selectedCountryData = countryState.countries.find(
        (obj) => obj.name.common === selectedCountry
      );

      if (selectedCountryData) {
        const dialingCode = selectedCountryData.idd
          ? selectedCountryData.idd.root + selectedCountryData.idd.suffixes
          : "N/A";

        console.log("Selected Country:", selectedCountry);

        console.log(dialingCode + inputValue);

        try {
          console.log("parsing into the api " + dialingCode + inputValue);
          const response = await axios.post(
            "https://inobackend-production.up.railway.app/api/v1/user/register",
            {
              mobileNumber: `${dialingCode + inputValue}`,
            }
          );
          console.log(response.data.result._id);
          localStorage.setItem("userId", response.data.result._id);
          walletContext.setUserId(response.data.result._id);

          setSpinner(false);
          const url = `/auth/verifyphone/${dialingCode + inputValue}`;
          navigate(url);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  //
  useEffect(() => {
    const fetchData = async () => {
      try {
        // fetch spinner
        setCountryState({
          ...countryState,
          loading: true,
        });

        // fetch data
        const dataUrl = `https://restcountries.com/v3.1/all`;
        const response = await axios.get(dataUrl);
        setCountryState({
          ...countryState,
          countries: response.data,
          loading: false,
        });
      } catch (error) {
        setCountryState({
          ...countryState,
          loading: false,
          errorMessage: "Sorry, something went wrong",
        });
      }
    };

    fetchData();
  }, []);

  const { loading, errorMessage, countries } = countryState;
  const [selectedCountry, setSelectedCountry] = useState("Oman");

  // find selected country data
  const searchSelectedCountry = countries.find(
    (obj) => obj.name.common === selectedCountry
  );

  return (
    <React.Fragment>
      <form>
        <div className="bg-white xs:bg-[#cb22290f] smd  h-max pb-20 mx-5 mt-40  xs:mt-16 w-max md:mx-auto l lg:mx-auto rounded-md shadow-md xs:w-[91%]">
          {/* body section */}
          <div>
            {loading ? (
              <div className="flex justify-center items-center h-48 bg-[#cb22290f]">
                <button
                  type="button"
                  className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none shark:focus:outline-none shark:focus:ring-1 shark:focus:ring-gray-600"
                >
                  <span
                    className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                    role="status"
                    aria-label="loading"
                  />
                  Loading
                </button>
              </div>
            ) : (
              <div className="grid justify-center xs:gap-2 mt-14 xs:mt-0 mx-10 md:space-y-10 sm:space-y-10 bg-white xs:bg-[#cb222900]">
                <p className="text-center  text-black  font-all font-semibold mt-8 ">
                  Select your country code or enter phone number to continue{" "}
                </p>

                <div className="relative  text-left flex justify-center ">
                  <button
                    onClick={toggleDropdown}
                    className="  flex flex-row items-center justify-center gap-3   py-3 px-4 inline-fex items-ceter gap-x-2 text-sm font-semibold rounded-lg border  bg-gray-400 text-black disabled:opacity-50 disabled:pointer-events-none shark:focus:outline-none shark:focus:ring-1 shark:focus:ring-gray-600 shark:bg-white shark:text-gray-800"
                  >
                    <span className="font-all">
                      {" "}
                      {selectedCountry || "--Select Country--"}
                    </span>
                    {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </button>

                  {isOpen && (
                    <ul className="absolute h-96 overflow-scroll z-10 mt-8 w-96 xs:w-[22rem] bg-white border rounded-md shadow-md fade-in-left">
                      <div className="max-w-sm mx-auto mb-2 sticky top-0">
                        <input
                          type="text"
                          placeholder="Search country"
                          className="w-full h-14 bg-white p-[10px] rounded-md  border-b-2 text-black border-t-0 border-l-0 border-r-0   focus:ring-white focus:border-b-2 focus:outline-none font-all"
                          value={searchInput} // Bind the input value to the state
                          onChange={handleSearchInputChange} // Use the new function to handle input change
                          required
                        />

                        <button className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-700 bg-gray-100 border border-gray-300 rounded-r-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
                          <IoIosSearch />
                        </button>
                      </div>

                      {(filteredCountries.length > 0
                        ? filteredCountries
                        : countries
                      ).map((item) => (
                        <li
                          key={uuidv4()}
                          onClick={() => handleCountrySelect(item.name.common)}
                          className={`flex items-center p-2 cursor-pointer ${
                            selectedCountry === item.name.common
                              ? "bg-blue-500 text-white"
                              : ""
                          }`}
                        >
                          <img
                            className="w-8 h-8 mr-2"
                            src={item.flags && item.flags.png}
                            alt={`Flag of ${item.name.common}`}
                          />
                          <span>{item.name.common}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div>
                  {searchSelectedCountry && (
                    <div className="flex space-x-4">
                      <div className="flex items-end border-b-2 border-gray-500 pb-2">
                        <img
                          className=" h-8"
                          src={
                            searchSelectedCountry.flags &&
                            searchSelectedCountry.flags.png
                          }
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="h-14 text-xl flex items-end border-b-2 border-gray-500 pb-3 md:text-2xl font-allw">
                          {searchSelectedCountry.idd &&
                            searchSelectedCountry.idd.root}
                          {searchSelectedCountry.idd &&
                            searchSelectedCountry.idd.suffixes}
                        </p>
                      </div>
                      <div>
                        <input
                          placeholder="Enter phone number"
                          className="w-full h-14 bg-white xs:bg-transparent text-xl border-b border-black focus:ring-white focus:border-b-2 focus:outline-none font-all"
                          value={inputValue}
                          onChange={handleInputChange}
                          onKeyDown={(e) => {
                            // Allow only numeric values and some special keys (backspace, delete, arrow keys)
                            if (
                              !/^[0-9]$/.test(e.key) &&
                              ![
                                "Backspace",
                                "Delete",
                                "ArrowLeft",
                                "ArrowRight",
                              ].includes(e.key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                          type="tel"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className={showinputerror ? "block" : "hidden"}>
                  <span className="text-sm text-red-600 fade-in flex justify-center">
                    Enter Phone Number to continue.
                  </span>
                </div>
              </div>
            )}

            <button
              onClick={handlePrint}
              className="lg:mt-[7rem] cursor-pointer xs:mt-[6rem] flex justify-center items-center mx-auto m-auto bg-[#CB2229] text-white rounded-full font-all p-[6px] w-[18rem] transform hover:scale-105 duration-500 ease-in-out "
              fdprocessedid="yajzdu"
            >
              {spinner ? (
                <>
                  <span className="flex flex-row gap-2 items-center text-black">
                    <Spinner />
                  </span>
                </>
              ) : (
                <>Get otp</>
              )}
            </button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default CountrySelect;
