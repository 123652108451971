import React, { useEffect } from 'react';

import { useState } from "react";
import MarketTrendAllMain from './MarketTrendAll/MarketTrendAllMain';
import MandiMain from './MandiRates/MandiMain';
import GovMain from './GovProjects/GovMain';

function MarketTrendMain() {

const tabs = [

    {
        label: "All",
        content: <MarketTrendAllMain />,
      },
      {
        label: "Mandi Rates",
        content: <MandiMain/>,
      },
      {
        label: "Gov Projects",
        content: <GovMain />,
      },


]
const [activeTabIndex, setActiveTabIndex] = useState(0);
useEffect(() => {

  window.scrollTo(0, 0);
}, []);

  return (
  <>
  
  <div className="  bg-layout shadow-md drop-shadow-md lg:w-[155vh] h-max  mb-[7rem]  lg:ml-[268px] relative top-[1rem] fade-in-left ">
        <div className=" ml-4 flex space-x-3 border-b justify-center gap-[5rem]">
          {/* Loop through tab data and render button for each. */}
          {tabs.map((tab, idx) => {
            return (
              <button
                key={idx}
                className={`py-2 border-b-4 transition-colors duration-300 text-[19px] font-all font-semibold ${
                  idx === activeTabIndex
                    ? "border-[#CB2229]  "
                    : "border-transparent hover:border-gray-200"
                }`}
                // Change the active tab on click.
                onClick={() => setActiveTabIndex(idx)}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
        {/* Show active tab content. */}
        <div className=" ">
          <p>{tabs[activeTabIndex].content}</p>
        </div>
      </div>
  
  </>
  )
}

export default MarketTrendMain