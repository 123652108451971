// api.js (or a separate file for API functions)
import { fetchSProductDetails, fetchServiceDetails, fetchJobDetails, fetchPostDetails, GovProjectDetails, delete_product_input, delete_service_input, delete_job_input, delete_post_input, delete_project_input } from "./userRegestration/CollectionForm";
// 
export const fetchDetailsByType = async (type, id) => {
  console.log("type", type)
  switch (type) {
    case "PRODUCT":
      return await fetchSProductDetails(id);
    case "SERVICE":
      return await fetchServiceDetails(id);
    case "JOB":
      return await fetchJobDetails(id);
    case "POST":
      return await fetchPostDetails(id);
    case "PROJECT":
      return await GovProjectDetails(id);
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};
// 
export const deleteItemByType = async (type, id) => {
  switch (type) {
    case "PRODUCT":
      return await delete_product_input({ productId: id });
    case "SERVICE":
      return await delete_service_input({ serviceId: id });
    case "JOB":
      return await delete_job_input({ jobId: id });
    case "POST":
      return await delete_post_input({ posttId: id });
    case "PROJECT":
      return await delete_project_input({ projectId: id });
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};
//