import React from "react";
import { NavLink } from "react-router-dom";
import AddProjectCarousel from "./AddProjectCarousel";
import BuyerNear from "./BuyerNear";
import AddCommunityPost from "./AddCommunityPost";
import { HiArrowLongLeft } from "react-icons/hi2";
import AddProjectProductsCarousel from "./AddProjectProductsCarousel";
import ProductForYou from "../../FindProduct/Allmain/ProductForYou";
export default function AddProjectAllmain() {
  return (
    <>
      <div className=" flex items-center justify-center h-48 mb-8 ">
        <AddProjectCarousel />
      </div>
      <div className="flex flex-row items-center">
        <NavLink to="/">
          <div
            className=" lg:relative  lg:w-[100%]  lg:left-[1rem]    flex flex-row item-center justify-start gap-3 z-10 top-[11rem]"
            style={{ alignItems: "center" }}
          >
            <span className="text-[32px] text-[#4b5563]">
              <HiArrowLongLeft />
            </span>
            <h1 className="font-semibold  text-[#4b5563] text-[20px]">
              Go Back to home page
            </h1>
          </div>
        </NavLink>
      </div>
      <div
        className=" mt-[13rem] flex items-center justify-center rounded  "
        style={{ border: "1px solid #D2D2D2" }}
      >
        <div className=" w-[140vh]">
          <AddProjectProductsCarousel />
        </div>
      </div>
      {/* <div
        className=" mt-[4rem] flex items-center justify-center rounded  "
        style={{ border: "1px solid #D2D2D2" }}
      >
        <div className=" w-[140vh]">
          <ProductForYou />
        </div>
      </div> */}
    </>
  );
}
