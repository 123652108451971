import GovProjectSlider from "./GovProjectSlider";
import GovProjects from "./GovProjects";
import OtherProjects from "./OtherProjects";


export default function GovMain() {
  return (
    <>
    <div className="gov gov-main main-div">
        <div className="m m-2">
        <div className=" flex items-center justify-center h-48 mb-8 ">
            <GovProjectSlider />
          </div>
          <div
            className=" mt-[13rem] flex items-center justify-center h-48 rounded  lg:h-[24rem] "
            style={{ border: "1px solid #D2D2D2" }}
          >
            <div className="w-[140vh]">
              <GovProjects />
            </div>
          </div>
          <div
            className=" mt-[2rem] flex items-center justify-center h-48 rounded  lg:h-[24rem] "
            style={{ border: "1px solid #D2D2D2" }}
          >
            <div className="w-[140vh]">
              <OtherProjects />
            </div>
          </div>
        </div>
    </div>
    
    
    </>
  )
}
