// WalletContext.js

import React, { createContext, useContext, useState, useEffect } from "react";
import {
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithPopup,
  GithubAuthProvider,
} from "firebase/auth";
import { auth, db } from "../../../firebase";
import {
  collection,
  doc,
  documentId,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import {
  fetch_wallet_info,
  fetch_posts,
  fetch_Profile_Status,
} from "../../../api/userRegestration/CollectionForm";
// import { useNavigate } from "react-router-dom";
const WalletContext = createContext();
// 
export const WalletProvider = ({ children }) => {
  // states
  const [walletData, setWalletData] = useState();
  const [postlist, setPostlist] = useState();
  const [profiledata, setProfiledata] = useState({});
  const [resellerview, setresellerview] = useState(false);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [userID , setUserId] = useState("")
  const [keyfunction, setkeyfunction] = useState(() => {});
  // google sign in 
  async function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    try {
      const res = await signInWithRedirect(auth, googleAuthProvider);
      console.log("res google", { res });
    } catch (err) {
      alert(err.message);
    }
  }
  // github sign in  
  async function githubSignIn() {
    const githubAuthProvider = new GithubAuthProvider();
    try {
      const res = await signInWithRedirect(auth, githubAuthProvider);
      console.log("res github", { res });
    } catch (err) {
      alert(err.message);
    }
  }

  //
  useEffect(() => {
    const currentDate = new Date();
    console.log(`Wallet - context created/updated at: ${currentDate}`);
  }, [walletData, profiledata]);
  // toggle reseller && reselller nav view 
  const togglereseller = () => {
    console.log("kjsbwkjdfbdwkjfbwfkjwebf bhai gdsss");
    setresellerview(true);
  };
  //  fetch wallet balance
  const fetch_wallet_balance = () => {
    fetch_wallet_info()
      .then((response) => {
        console.log("API Response:", response);
        setWalletData(response.result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
//  fetch Post List
  const fetch_post_list = () => {
    fetch_posts()
      .then((respone) => {
        console.log("from post response", respone);
        setPostlist(respone.result);
      })
      .catch((error) => {
        console.error("Error post:", error);
      });
  };
  // fetch profile status
  const fetch_profile_status = () => {
    fetch_Profile_Status()
      .then((response) => {
        console.log("API Response from contet:", response);
        setProfiledata(response.result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  // mob base view of  chat window
  function detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
// firestore logout function 
function logOut() {
  return signOut(auth);
}

  // profile creation
  const profileCreation = (user) => {
    if (user) {
      try {
        setDoc(doc(db, "users", user.uid), {
          uid: user.uid,
          displayName: user.displayName
            ? user.displayName
            : user.email.match(/^([^@]*)@/)[1],
          profile_pictures: user.photoURL,
          created_at: Timestamp.now(),
          email: user.email,
        }).then((res) => {
          toast.success("Profile Creation Successfully!");
          // navigate("/chat");
        });
      } catch (err) {
        console.log(err, "firestore err");
        window.confirm(
          "Something went wrong during profile creation, Please Retry"
        ) === true && window.location.reload();
      }
    }
  };
  // google signup popup
  function googleSignInWithPopup() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider)
      .then((result) => {})
      .catch((err) => alert(err.message));
  }
  // github singin popup
  function githubSignInWithPopup() {
    const githubAuthProvider = new GithubAuthProvider();
    return signInWithPopup(auth, githubAuthProvider)
      .then((result) => {})
      .catch((err) => alert(err.message));
  }
  // executing functions
  useEffect(() => {
    //
    if (user?.uid) {
      const q = query(
        collection(db, "users"),
        where(documentId(), "==", user?.uid)
      );
      onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.docs.length) {
          profileCreation(user);
        }
      });
    }

    //
    if (resellerview === true) {
      togglereseller();
    }
    fetch_wallet_balance();
    fetch_post_list();
    fetch_profile_status();
    togglereseller();
  }, [user]);
  //
  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  //
  return (
    <WalletContext.Provider
      value={{
        walletData,
        setWalletData,
        postlist,
        profiledata,
        setProfiledata,
        fetch_Profile_Status,
        resellerview,
        setresellerview,
        togglereseller,
        user,
        loading,
        googleSignIn,
        githubSignIn,
        detectMob,
        googleSignInWithPopup,
        githubSignInWithPopup,
        logOut,
        setUserId,
        userID,
        keyfunction, setkeyfunction
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error("useWallet must be used within a WalletProvider");
  }
  return context;
};
