import React from 'react'

function Privacypolicymain() {
  return (
<>

<div className="swing-in-top-fwd">
        <div className=" container   bg-layout drop-shadow-md w-[83%] mx-auto relative left-[7rem] top-[1rem]">
          <div className="  w-[83%] mx-auto ">
            <h1 className=" text-center text-[20px] mt-1 font-extrabold font-all title-font mb-4 text-gray-900">
              {" "}
             Privacy Policy
            </h1>

            <p className=" font-all font-semibold">
            Welcome to our website, and thank you for taking the time to review our privacy policy. We respect your privacy, and we are committed to protecting your personal information. By accessing and using our website, you are accepting and agreeing to the terms and conditions of this policy.
            </p>

            {/* 2  */}
            <div className="terms mt-4 ">
              <h1 className="font-semibold  flex justify-start">
              
                <span className="font-semibold ml-1">
                  {" "}
                  Personal Information Collection and Use:

                </span>
              </h1>
              <p
                className="mt-2 font-all ml-1
      "
              >
              We may collect personal information from you when you voluntarily submit it through our website, such as your name, email address, and phone number. We use this information to provide you with the services and information you have requested, and to communicate with you about our products, services, and promotions. 
              <br />
              We will not sell, rent, or share your personal information with third parties for their own marketing purposes.

              </p>
            </div>
            {/* 3 */}
            <div className="terms mt-4 ">
              <h1 className="font-semibold  flex justify-start">
              
                <span className="font-semibold ml-1">
                  {" "}
                  Cookies and Tracking:
                </span>
              </h1>
              <p
                className="mt-2 font-all ml-1
      "
              >
               We may use cookies and other tracking technologies to collect information about your use of our website. This information includes your IP address, browser type, operating system, and other technical details. We use this information to improve the functionality and performance of our website, analyze website usage, and personalize your experience on our website.
              </p>
            </div>
            {/* 4  */}
            <div className="terms mt-4 ">
              <h1 className="font-semibold  flex justify-start">
             
                <span className="font-semibold ml-1">
                  {" "}
                  Security:
                </span>
              </h1>
              <p
                className="mt-2 font-all ml-1
      "
              >
             We take reasonable measures to protect the personal information we collect through our website. However, we cannot guarantee the security of your information, and we are not responsible for any unauthorized access to or disclosure of your personal information.

              </p>
            </div>
            {/* 5 */}
            <div className="terms mt-4 ">
              <h1 className="font-semibold  flex justify-start">
             
                <span className="font-semibold ml-1">
                  {" "}
                  Contact Us:
                </span>
              </h1>
              <p
                className="mt-2 font-all ml-1 mb-5
      "
              >
           You agree to indemnify us and hold us harmless from any claims, costs, or damages incurred due to your use of  our website.
              </p>
            </div>
            {/* 6  */}
        
          
       
          </div>
        </div>
      </div>

</>
  )
}

export default Privacypolicymain
