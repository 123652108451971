import React, { useEffect, useState } from "react";
// importing firebase db.storage from the fireEvent.config
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
// firebase imports  ||
import {
  registerUserdata,
  getAllProductCategories,
} from "../../../src/api/userRegestration/CollectionForm";
import Logo from "../../assets/logo/Logo-Trans.png";
import image from "../../assets/logo/auth.png";
//
import { useNavigate, Link } from "react-router-dom";
import Spinner from "../../component/Spinner";
import { useWallet } from "../Wallet/Context/WalletContext";

function RegisterUserForm() {
  const userID = useWallet();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("token");

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    aboutYourself: "",
    location: "",
    useCurrentLocation: "",
    interest: "",
    identification: "",
    imageFile: null,
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [categories, setCategories] = useState([]);
  const [imageBase64, setImageBase64] = useState(null);
  const [err, setErr] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      convertImageToBase64(file);
    } else {
      setSelectedImage(null);
      setImageBase64(null);
    }
  };

  const convertImageToBase64 = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target.result;
      setImageBase64(base64Data);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();

    try {
      const userData = {
        name: formData.identification,
        userName: formData.fullname,
        email: formData.aboutYourself,
        bio: formData.interest,
        dob: formData.interest,
        location: formData.location,
        profilePic: imageBase64,
        coverPic:
          "https://images.unsplash.com/photo-1556742044-3c52d6e88c62?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c21hbGwlMjBzaXplfGVufDB8fDB8fHww",
      };

      const userId = userID.userID;
      await setDoc(doc(db, "users", userId), {
        uid: userId,
        displayName: formData.fullname,
        photoURL: imageBase64,
      });
      await setDoc(doc(db, "userChats", userId), {});
      const registeredUser = await registerUserdata(userData);
      console.log("Registered User:", registeredUser, "result");
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Error registering user:", error.message);
    }
  };
  useEffect(() => {
    getAllProductCategories(accessToken)
      .then((result) => {
        setCategories(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);
  return (
    <>
      <>
        {/* 01  */}
        <div className="flex flex-row  xs:justify-center items-center bg-[#cb22281c]">
          <div className=" xs:hidden  item-1 h-screen lg:w-[100vh]">
            <div className="flex flex-col justify-center items-center mt-12 gap-[12pxs]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[134pxl.] bounce-in-top  "
              />
            </div>

            <div className="text">
              <h1 className="text-center font-bold text-[35px] leading-[70px] text-[#738482]">
                Welcome to INO
              </h1>
              <p className="text-center  text-[#738482] font-all font-semibold ">
                Register now to gain access to your account{" "}
              </p>
            </div>
            <div className="image relative top-[14px]">
              <img
                src={image}
                className="mx-ato m-auto h-[21rem] image-coin"
                alt=""
              />
            </div>
            <div className="flex flex-row items-center gap-4 justify-center mt-[7rem]">
              <p className="text-[14px]">License Policy</p>
              <p className="border-x p-[8px]  text-[14px] border-black">
                Privacy Policy
              </p>
              <p className="text-[14px]">Terms and Conition</p>
            </div>
            <p className="text-[#738482] text-[13px] text-center">
              Copyrights © 2023 All Rights Reserved by{" "}
              <span className="text-[#CB2229]">INO</span>{" "}
            </p>
          </div>
          {/* 01 end  */}
          {/* 02  */}
          <div className="item-form">
            <div className="flex flex-col">
              <div className="flex flex-col items-center lg:hidden  --logo  justify-center xs:mt-[1rem]">
                <img
                  src={Logo}
                  alt=""
                  className="lg:w-[134px] xs:h-[49px] bounce-in-top mx-auto "
                />
                <div className="text">
                  <h1 className="text-center font-bold text-[17px] leading-[70px] text-black mt-1">
                    Welcome to INO
                  </h1>
                  <p className="text-center  text-black font-all font-semibold ">
                    Register now to gain access to your account{" "}
                  </p>
                </div>
              </div>
            </div>
            <section className="max-w-4xl p-6  lg:w-[93vh] sm:w-[93vh] mx-auto bg-white rounded-md shadow-md  mt-[2rem] fade-in-right">
              <form onSubmit={handleSubmit} className="relative top-[-10px]">
                <div className="grid grid-cols-1 gap-6 mt-4 xs:mt-1 sm:grid-cols-2">
                  <div>
                    <label className="text-black" htmlFor="username">
                      Full name
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      name="fullname"
                      value={formData.fullname}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  {/* <div>
                    <label className="text-black" htmlFor="username">
                      username
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      name="userName"
                      value={formData.userName}
                      onChange={handleInputChange}
                    />
                  </div> */}
                  {/* <div>
                    <label className="text-black" htmlFor="username">
                      email
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div> */}
                  <div>
                    <label className="text-black" htmlFor="username">
                      About yourself
                    </label>
                    {/*  */}
                    <input
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      name="aboutYourself"
                      value={formData.aboutYourself}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div>
                    <label className="text-black" htmlFor="username">
                      Location
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      name="location"
                      value={formData.location}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="coolinput flex flex-col w-fit-content">
                    <label htmlFor="select" className="text-black">
                      identification
                    </label>
                    <select
                      name="identification"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring cursor-pointer"
                      value={formData.identification}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="" className="p-2" disabled>
                        Select identification
                      </option>
                      {categories.map((identification) => (
                        <option
                          key={identification._id}
                          value={identification.name}
                          className="bg-white cursor-pointer transition-colors duration-300 p-2"
                        >
                          {identification.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <div>
                    <label className="text-black" htmlFor="username">
                      Interest
                    </label>
                    <input
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      name="interest"
                      value={formData.interest}
                      onChange={handleInputChange}
                    />
                  </div> */}
                  <div className="coolinput flex flex-col w-fit-content">
                    <label htmlFor="select" className="text-black">
                      interest
                    </label>
                    <select
                      name="interest"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring cursor-pointer"
                      value={formData.interest}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="" className="p-2" disabled>
                        Select interest
                      </option>
                      {categories.map((interest) => (
                        <option
                          key={interest._id}
                          value={interest.name}
                          className="bg-white cursor-pointer transition-colors duration-300 p-2"
                        >
                          {interest.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* base 64  */}
                  <div className="flex flex-col">
                    <label className="text-black" htmlFor="username">
                      profile pic
                    </label>

                    <div className="flex items-center justify-center w-full">
                      <label
                        style={{
                          display: selectedImage ? "none" : "block",
                        }}
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full  h-[8rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            className="w-8 h-8 mb-4 text-gray-500 shark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 shark:text-gray-400">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>
                          <p className="text-xs text-gray-500 shark:text-gray-400">
                            SVG, PNG, JPG, or GIF (MAX. 800x400px)
                          </p>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                          onChange={handleImageChange}
                        />
                      </label>
                      {selectedImage && (
                        <div className="flex items-center  w-auto p-[10px]  h-[8rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600">
                          <img
                            className="w-full h-[7rem] rounded-md mx-auto "
                            src={selectedImage}
                            alt="Selected"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* base 64 end  */}
                  {/* banner image  */}
                  {/* <div className="flex flex-col">
                    <label className="text-black" htmlFor="username">
                      Banner image
                    </label>

                    <div className="flex items-center justify-center w-full">
                      <label
                        style={{
                          display: selectedImage ? "none" : "block",
                        }}
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full  h-[8rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            className="w-8 h-8 mb-4 text-gray-500 shark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 shark:text-gray-400">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>
                          <p className="text-xs text-gray-500 shark:text-gray-400">
                            SVG, PNG, JPG, or GIF (MAX. 800x400px)
                          </p>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                          onChange={handleImageChange}
                        />
                      </label>
                      {selectedImage && (
                        <div className="flex items-center  w-auto p-[10px]  h-[8rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600">
                          <img
                            className="w-full h-[7rem] rounded-md mx-auto "
                            src={selectedImage}
                            alt="Selected"
                          />
                        </div>
                      )}
                    </div>
                  </div> */}
                  {/* banner image end  */}
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="submit"
                    className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-[#cb2229] rounded-md focus:outline-none focus:bg-gray-600"
                  >
                    {loader ? (
                      <>
                        <span className="flex flex-row items-center gap-2">
                          Registring
                          <Spinner />
                        </span>
                      </>
                    ) : (
                      <> Register</>
                    )}
                  </button>
                </div>
              </form>
            </section>
          </div>
        </div>
        {/* 02 end  */}
      </>
    </>
  );
}

export default RegisterUserForm;
