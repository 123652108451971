import React, { useEffect, useState } from "react";
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { fetchSProductDetails } from "../../../../../../../api/userRegestration/CollectionForm";

// import ProductBuyPAgeSlider from "../HomePAll/ProductBuyPAgeSlider";

export default function Productpage() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [productdetails, setproductdetails] = useState();
  const Home = () => {
    navigate(`/`);
  };

  const location = useLocation();

  const handleClick = () => {
    location.replace("ProductPage");
  };

  useEffect(() => {
    fetchSProductDetails(productId)
      .then((response) => {
        console.log("API Response:", response);
        setproductdetails(response.result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [productId]);

  return (
    <>
      <div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh] mb-[7rem] lg:ml-[268px] relative top-[1rem]">
        {productdetails && (
          <>
            <div className="--action breadcrumb--navigate-path ml-8 mt-8">
              <Breadcrumb aria-label="Default breadcrumb example">
                <Breadcrumb.Item icon={HiHome}>
                  <p className="cursor-pointer " onClick={Home}>
                    Home
                  </p>
                </Breadcrumb.Item>

                <Breadcrumb.Item
                  className="cursor-pointer"
                  onClick={handleClick}
                >
                  Product
                </Breadcrumb.Item>

                <Breadcrumb.Item>{productdetails.productName}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <section className="/">
              <div className="container mx-auto px-4">
                <div className="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
                  <div className="lg:col-span-3 lg:row-end-1">
                    <div className="lg:flex lg:items-start">
                      <div className="lg:order-2 lg:ml-5">
                        <div className="max-w-xl overflow-hidden rounded-lg">
                          <img
                            className="h-full w-full max-w-full object-cover"
                            src={productdetails.mediaUrl}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                        <div className="flex flex-row items-start lg:flex-col">
                          <button
                            type="button"
                            className="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 border-gray-900 text-center"
                          >
                            <img
                              className="h-full w-full object-cover"
                              src={productdetails.mediaUrl}
                              alt=""
                            />
                          </button>
                          <button
                            type="button"
                            className="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 border-transparent text-center"
                          >
                            <img
                              className="h-full w-full object-cover"
                              src={productdetails.mediaUrl}
                              alt=""
                            />
                          </button>
                          <button
                            type="button"
                            className="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 border-transparent text-center"
                          >
                            <img
                              className="h-full w-full object-cover"
                              src={productdetails.mediaUrl}
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                    <h1 className="sm: text-2xl font-bold text-gray-900 sm:text-3xl font-all">
                      {productdetails.productName} <span> </span>
                    </h1>
                    <div className="mt-5 flex items-center">
                      <div className="flex items-center">
                        <svg
                          className="block h-4 w-4 align-middle text-yellow-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                            className=""
                          />
                        </svg>
                        <svg
                          className="block h-4 w-4 align-middle text-yellow-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                            className=""
                          />
                        </svg>
                        <svg
                          className="block h-4 w-4 align-middle text-yellow-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                            className=""
                          />
                        </svg>
                        <svg
                          className="block h-4 w-4 align-middle text-yellow-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                            className=""
                          />
                        </svg>
                        <svg
                          className="block h-4 w-4 align-middle text-yellow-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                            className=""
                          />
                        </svg>
                      </div>
                      <p className="ml-2 text-sm font-medium text-gray-500">
                        1,209 Reviews
                      </p>
                    </div>
                    <h2 className="mt-8 text-base text-gray-900">
                      Product Categories
                    </h2>
                    <div className="mt-3 flex select-none flex-wrap items-center gap-1">
                      <label>
                        <input
                          type="radio"
                          name="type"
                          defaultValue={productdetails.categorie} // Display the category directly
                          className="peer sr-only"
                          defaultChecked=""
                        />
                        <p className="peer-checked:bg-black peer-checked:text-white rounded-lg border border-black px-6 py-2 font-bold">
                          {productdetails.categorie}
                        </p>
                      </label>
                    </div>
                    {/*  */}
                    {/* Product rate  */}
                    <h2 className="mt-8 text-base text-gray-900 font-bold font-all">
                      Product rate :{" "}
                      <span className="italic font-normal"> {productdetails.amount} </span>
                    </h2>
                    {/* minimum order quanity  */}
                    <h2 className="mt-3 text-base text-gray-900 font-bold font-all">
                      Minimum Order Quanity (MOD) :{" "}
                      <span className="italic font-normal"> {productdetails.moq} </span>
                    </h2>
                    <h2 className="mt-3 text-base text-gray-900 font-bold font-all">
                      MQ unit :{" "}
                      <span className="italic font-light"> {productdetails.mqu} </span>{" "}
                    </h2>
                    <h2 className="mt-3 text-base text-gray-900 font-bold font-all">
                      Product Details : <br />{" "}
                      <span className="italic font-normal">
                        {" "}
                        " {productdetails.details} "
                      </span>
                    </h2>

                    <div className="flex flex-col gap-[1rem]  mt-[1.1rem]">
                      <button className=" bg-[#F8F8F8]   border-[1px] border-[#CB2229] shadow-md lg:mx-auto p-[6px] w-[22rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                        leave a meassage
                      </button>
                      <button className=" bg-[#F8F8F8]  border-[1px] border-[#CB2229]  shadow-md lg:mx-auto p-[6px] w-[22rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                        chat now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="w-full mt-4 mb-4 border-[#a9a9a9]" />

              <div className="">
                {/* <div className="c-1 flex flex-row gap-[13px] justify-center">
                <span className="bg-[#E6E6E6] flex flex-row  gap-[6px] justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  {" "}
                  <AiOutlineEye /> views
                </span>
                <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  {" "}
                  <MdAdsClick /> clicks
                </span>
                <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  {" "}
                  <AiOutlineHeart /> Likes
                </span>

                <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  {" "}
                  <AiOutlineQuestionCircle /> Inquiries
                </span>
                <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  {" "}
                  <BsGraphUpArrow /> Ranking
                </span>
                <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  {" "}
                  <AiOutlineShareAlt /> share
                </span>
                
              </div> */}
              </div>
              <hr className="w-full mt-4 mb-4 border-[#a9a9a9]" />

              <div className="--other-product-sslider">
                {/* <ProductBuyPAgeSlider /> */}
              </div>
            </section>
          </>
        )}
      </div>
    </>
  );
}
