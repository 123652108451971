import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBb5DcMIewZAFd9pOOx7ZVFOUy-wf5lfH0",
  authDomain: "lamachat-252e8.firebaseapp.com",
  projectId: "lamachat-252e8",
  storageBucket: "lamachat-252e8.appspot.com",
  messagingSenderId: "552738939343",
  appId: "1:552738939343:web:4cc731010bfc0f4ea732ba"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()
