import React from 'react'
import MandiSlider from './MandiSLider'
import MandiRate from './MandiRate'

export default function MandiMain() {
  return (
  <>
  
  
  <div className="main main-mandi main-div">
    <div className="m m-2">
    <div className=" flex items-center justify-center h-48 mb-8 ">
            <MandiSlider/>
          </div>
    </div>


    <div
            className="  mt-[13rem] flex items-center justify-center h-48 rounded  lg:h-[24rem] "
            style={{ border: "1px solid #D2D2D2" }}
          >
            <div className="w-[140vh]">
              <MandiRate />
            </div>
          </div>
  </div>
  
  
  </>
  )
}
