import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

function UpdateFormComponent(details) {
  //
  const { type } = useParams();
  //
  useEffect(() => {
    console.log("details from nested components", details.details.moq);

  }, [details]);
  //
  const [formData, setFormData] = useState({
    field1: details.details.categorie || "",
    field2:  details.details.amount || "",
    field3: details.details.moq,
    field4: "",
    field5: details.details.details|| "", 
    field6: "",
    field7: "",
    imageFile: null,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  //
  let fields;
  switch (type) {
    case "PRODUCT":
      fields = [
        {
          label: "Product Categories",
          type: "text",
          placeholder: "Write here...",
          name: "field1",
          options: ["Piece (pc)", "Set", "Meter", "Gallon (GL)"],
          value: formData.field1,
          onchange: handleInputChange,
        },
        {
          label: "Product Rate",
          type: "text",
          placeholder: "Write here...",
          name: "field2",
          value: formData.field2,
          onchange: handleInputChange,
        },
        {
          label: "Minimum Order Quantity",
          type: "text",
          placeholder: "Write here...",
          name: "field3",
          value: formData.field3,
          onchange: handleInputChange,
        },
        {
          label: "MQ UNIT",
          type: "select",
          name: "field4",
          options: [
            "Piece (pc)",
            "Set",
            "Meter",
            "Roll",
            "Kilowatts",
            "Watts",
            "Kilogram (Kg)",
            "Gram (g)",
            "Quintal (Qt)",
            "Metric Tonnes",
            "Tonne (T)",
            "Liter (LT)",
            "Milliliter (mL)",
            "Barrel (bbL)",
            "Gallon (GL)",
          ],
          value: formData.field4,
          onchange: handleInputChange,
        },
        {
          label: "Product Details",
          type: "textarea",
          placeholder: "Write here...",
          name: "field5",
          value: formData.field5,
          onchange: handleInputChange,
        },
      ];
      break;
    case "SERVICE":
      fields = [
        {
          label: "Service Categories",
          type: "text",
          placeholder: "Write here...",
          options: ["Piece (pc)", "Set", "Meter", "Gallon (GL)"],
          name: "field1",
          value: formData.field1,
          onchange: handleInputChange,
        },
        {
          label: "Service Name",
          type: "text",
          placeholder: "Write here...",
          name: "field2",
          value: formData.field2,
          onchange: handleInputChange,
        },
        {
          label: "Relevant Skills Keywords",
          type: "text",
          placeholder: "Write here...",
          name: "field3",
          value: formData.field3,
          onchange: handleInputChange,
        },
        {
          label: "MQ UNIT",
          type: "select",
          name: "field4",
          options: ["Service", "Service", "Service"],
          value: formData.field4,
          onchange: handleInputChange,
        },
        {
          label: "Service Details",
          type: "textarea",
          placeholder: "Write here...",
          name: "field5",
          value: formData.field5,
          onchange: handleInputChange,
        },
      ];
      break;
    case "JOB":
      fields = [
        {
          label: "Job Title",
          type: "text",
          placeholder: "Write here...",
          name: "field1",
          value: formData.field1,
          onchange: handleInputChange,
        },
        {
          label: "Profession Categories",
          type: "select",
          placeholder: "Write here...",
          name: "field2",
          options: ["Service", "Service", "Service"],
          value: formData.field2,
          onchange: handleInputChange,
        },
        {
          label: "Salary Expectation",
          type: "number",
          placeholder: "Write here...",
          name: "field3",
          value: formData.field3,
          onchange: handleInputChange,
        },
        {
          label: "years of exprience",
          type: "text",
          name: "field4",
          value: formData.field4,
          onchange: handleInputChange,
        },
        {
          label: "Resume / CV",
          type: "text",
          placeholder: "Write here...",
          name: "field5",
          value: formData.field5,
          onchange: handleInputChange,
        },
        {
          label: "About Yourself",
          type: "text",
          placeholder: "Write here...",
          name: "field6",
          value: formData.field6,
          onchange: handleInputChange,
        },
        {
          label: "Relevant Skills / Keywords",
          type: "text",
          placeholder: "Write here...",
          name: "field7",
          value: formData.field7,
          onchange: handleInputChange,
        },
      ];
      break;
    case "POST":
      fields = [
        {
          label: "Post Title",
          type: "text",
          placeholder: "Write here...",
          name: "field1",
          value: formData.field1,
          onchange: handleInputChange,
        },
        {
          label: "Description",
          type: "text",
          placeholder: "Write here...",
          name: "field2",
          value: formData.field2,
          onchange: handleInputChange,
        },
      ];
      break;
    case "project":
      fields = [
        {
          label: "Project Name",
          type: "text",
          placeholder: "Write here...",
          name: "field1",
          value: formData.field1,
          onchange: handleInputChange,
        },
        {
          label: "Company Name",
          type: "text",
          placeholder: "Write here...",
          name: "field2",
          value: formData.field2,
          onchange: "",
        },
        {
          label: "Email Address",
          type: "text",
          placeholder: "Write here...",
          name: "field3",
          value: formData.field3,
          onchange: handleInputChange,
        },
        {
          label: "Phone number",
          type: "number",
          placeholder: "Write here...",
          name: "field4",
          value: formData.field4,
          onchange: handleInputChange,
        },
      ];
      break;
    default:
      fields = [];
  }

  useEffect(() => {
    console.log(type, "type");
  }, []);

  return (
    <>
      <div className="conditional _ _ action _ _ a">
        <div className="conditional _ _ action _ _ a">
          <div className="form">
            <form>
              <div className="flex flex-col mt-[3rem] mb-[2rem]">
                {fields.map((field, index) => (
                  <div key={index} className="form-input flex flex-col w-[47%]">
                    <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
                      <label
                        htmlFor={field.name}
                        className={`text text-sm text-black w-max px-2 rounded-md bg-[white] relative top-2.5 ml-2  bg-w-fit-content text-center`}
                      >
                        {field.label}
                      </label>
                      {field.type === "select" ? (
                        <select
                          name={field.name}
                          value={formData[field.name]}
                          className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] bg-white p-[10px]"
                          onChange={field.onchange}
                          required
                        >
                          <option value="">Select an option...</option>
                          {field.options.map((option, optionIndex) => (
                            <option key={optionIndex} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      ) : field.type === "textarea" ? (
                        <textarea
                          placeholder={field.placeholder}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={field.onchange}
                          rows="7"
                          className="px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] bg-white p-[10px]"
                          required
                        ></textarea>
                      ) : (
                        <input
                          type={field.type}
                          placeholder={field.placeholder}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={field.onchange}
                          className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] bg-white p-[10px]"
                          required
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateFormComponent;
