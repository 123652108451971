import React, { useEffect, useState } from "react";
import axios from "axios";
import { GoVerified } from "react-icons/go";
import { useWallet } from "../../../../Wallet/Context/WalletContext";
function CommunityPost() {
  const { postlist } = useWallet();

  //

  useEffect(() => {
    const currentDate = new Date();
    console.log(`post rendered at: ${currentDate}`);
  }, []);

  //
  return (
    <>
      <div
        className="flex items-center justify-center mt-10 rounded w-[93%]
          mx-auto "
        style={{ border: "1px solid #D2D2D2" }}
      >
        <div className="flex flex-col gap-4 mb-8">
          <h1 className="font-all font-bold text-start -auto text-[21px] lg:mt-[27px] mb-6">
            All Community Posts
          </h1>
          <section
            id="Projects"
            className="w-fit mx-auto grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5"
          >
            {postlist.map((item, index) => (
            
              <>
                <>{/* component */}</>
                <div className="flex flex-col justify-center">
                  <div
                    key={index}
                    className="relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 rounded-xl shadow-lg p-3 max-w-xs md:max-w-3xl mx-auto border border-white bg-white"
                  >
                    <div className="w-full md:w-1/3 bg-white grid place-items-center ">
                      <img
                        src={item.mediaUrl}
                        alt="tailwind logo"
                        className="rounded-xl h-[10rem] w-[7rem]"
                      />
                    </div>
                    <div className="w-full md:w-2/3 bg-white flex flex-col space-y-2 p-3">
                      <div className="flex justify-between item-center">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-yellow-500"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                          <p className="text-gray-600 font-bold text-sm ml-1">
                            4.96
                            <span className="text-gray-500 font-normal">
                              (76 reviews)
                            </span>
                          </p>
                        </div>

                        <div className="bg-gray-200 px-3 py-1 rounded-full text-xs font-medium text-gray-800 hidden md:block">
                          verified
                        </div>
                      </div>
                      <h3
                        className="font-black text-gray-800 md:text-3xl font-all"
                        style={{ fontSize: "21px" }}
                      >
                        {item.postTitle}
                      </h3>
                      <p className="md:text-lg text-gray-500 text-base">
                        {item.details.split(" ").slice(0, 6).join(" ")}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </section>

          <h1 className=" mt-2 cursor-pointer underline-offset-4 text-center font-all    hover:text-blue-600 w-[12%] mx-auto">
            See more
          </h1>
        </div>
      </div>
    </>
  );
}

export default CommunityPost;
