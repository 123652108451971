import React, { useState } from "react";

import { GrLocation } from "react-icons/gr";
import PromotionBillingAddress from "./PromotionBillingAddress";
import { useLocation } from "react-router-dom";

import {
  product_promotion,
  service_promotion,
  job_promotion,
  post_promotion,
} from "../../api/userRegestration/PromotionResponse";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useWallet } from "../Wallet/Context/WalletContext";
export default function PromotionFormBody({ data }) {
  //

  const [sliderValue, setSliderValue] = useState(90);

  // const { walletData } = useWallet();

  const [formData, setFormData] = useState({
    keywords: "",
    suggestedKeywords: "",
    location: "",
    budget: "",
    maxBiddingAmount: "",
    setDate: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSliderChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      sliderValue: parseInt(e.target.value),
    }));
  }; 
  //
  const handlesubmit = async (e) => {
    e.preventDefault();

    try {
      const path = location.pathname;

      console.log("Current execution for api parsing  :", path);
      let executePromotion;

      if (path.includes("/addProject/creatpost")) {
        console.log("bla bla bla ");
        executePromotion = await post_promotion({
          postId: data,
          duration: "1",
          bidAmount: "4",
          keyword: "k",
          budget: "1",
        });
      } else if (path.includes("findproduct")) {
        executePromotion = await product_promotion({
          productId: data,
          duration: "1",
          bidAmount: "4",
          keyword: "k",
          budget: "1",
        });
      }
      //
      else if (path.includes("ListProject")) {
        executePromotion = await service_promotion({
          serviceId: data,
          duration: "1",
          bidAmount: "4",
          keyword: "k",
          budget: "1",
        });
      } else if (path.includes("exploreService")) {
        executePromotion = await service_promotion({
          serviceId: data,
          duration: "1",
          bidAmount: "4",
          keyword: "k",
          budget: "1",
        });
      } else if (path.includes("findjobs")) {
        executePromotion = await job_promotion(
          /* parameters for job promotion */

          {
            jobId: data,
            duration: "1",
            bidAmount: "4",
            keyword: "k",
            budget: "1",
          }
        );
      } else if (path.includes("PostJob")) {
        executePromotion = await job_promotion(
          /* parameters for job promotion */

          {
            jobId: data,
            duration: "1",
            bidAmount: "4",
            keyword: "k",
            budget: "1",
          }
        );
      } else if (path.includes("addProject")) {
        executePromotion = await product_promotion({
          productId: data,
          duration: "1",
          bidAmount: "4",
          keyword: "k",
          budget: "1",
        });
      }
      // else if (path.includes("addProject/createPost")) {
      //   executePromotion = await post_promotion({
      //     postId: data,
      //     duration: "1",
      //     bidAmount: "4",
      //     keyword: "k",
      //     budget: "1",
      //   });

      // }
      else {
        executePromotion = await post_promotion({
          postId: data,
          duration: "1",
          bidAmount: "4",
          keyword: "k",
          budget: "1",
        });
      }

      console.log(executePromotion, "status of promoted module");
      toast.success("Promoted successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error(error);
    }
  };

  //
  return (
    <>
      <div className=" w-[93%] mx-auto p-[5px]">
        <h1 className="text-center my-4 font-bold">promotion</h1>

        <form onSubmit={handlesubmit}>
          <div className="item-b  mt-4 mb-2">
            <div className="border border-[#cfcaca82]   rounded-md w-[68%] mx-auto shadow-sm mb-3 ">
              <h1 className="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
                Select Keywords
              </h1>
              {/* - a  */}
              <div className="coolinput flex mx-auto flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="Keywords"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Add Keywords
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="keywords"
                  value={formData.keywords}
                  onChange={handleInputChange}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                />
              </div>
              {/* - b  */}
              <div className="coolinput flex mx-auto flex-col w-fit-content static max-w-240 lg:w-[32rem] mb-3 ">
                <label
                  htmlFor="suggestedKeywords"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Suggested Keywords
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="suggestedKeywords"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                  value={formData.suggestedKeywords}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="item-c mt-4 mb-2">
            <div className="border border-[#cfcaca82]   rounded-md w-[68%] mx-auto shadow-sm mb-3 ">
              <h1 className="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
                Advertise Near Location
              </h1>
              {/*  */}
              <div className="--get-location drop-c flex flex-col justify-center rounded-full mx-auto p-[9px] w-[14rem]">
                <span className="flex flex-row items-center justify-center gap-2">
                  <GrLocation /> Use Current Location
                </span>
              </div>
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] mx-auto ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Location
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="location"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                  value={formData.location}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-3/4 mx-auto mt-4 mb-4">
                <label
                  htmlFor="range"
                  className=" font-all  text-lg font-semibold"
                >
                  Selected Distance ( {sliderValue} km):
                </label>
                <input
                  type="range"
                  id="range"
                  name="range"
                  min="20"
                  max="500"
                  value={sliderValue}
                  onChange={handleSliderChange}
                  className="w-full mt-2 bg-yellow-200"
                />
                <div className="flex justify-between mt-2">
                  <span>20 km</span>
                  <span>{sliderValue} km</span>
                </div>
              </div>

              {/*  */}
            </div>
            <div className="item-d setbudget border border-[#cfcaca82]   rounded-md w-[68%] mx-auto shadow-sm mb-3 mt-4">
              <h1 className=" font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
                Add Budget
              </h1>
              {/* a  */}
              <div className="flex flex-col justify-center items-center">
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                  >
                    Enter your Budget
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="budget"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                    value={formData.budget}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                  >
                    Max Bidding amount
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="maxBiddingAmount"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                    value={formData.maxBiddingAmount}
                    onChange={handleInputChange}
                  />
                </div>
                {/* b  */}
                <div className="flex flex-row items-center justify-start gap-[20rem] my-7 ">
                  <h1 className="font-all font-semibold flex flex-col">
                    {" "}
                    <span>Current balance</span>
                    <div className="flex flex-row gap-2 items-center">
                      <span className="font-extrabold font-sans text-[24px] ">
                        {" "}
                        {/* {walletData.balance.toLocaleString("en-IN")}{" "} */}
                      </span>{" "}
                      inr
                    </div>
                  </h1>
                  <button className=" bg-[#cac5c585] shadow-md lg:mx-auto p-[6px] w-[13rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                    add money
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="--item-d --run add-money">
            <div className="border border-[#cfcaca82]   rounded-md w-[68%] mx-auto shadow-sm mb-3 mt-4">
              <h1 className="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
                How long will u take to run this ad
              </h1>
              <div className="flex flex-col items-center justify-center gap-[9px]">
                <div className="flex flex-row items-center gap-2">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value=""
                    name="default-radio"
                    className=" w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 shark:focus:ring-blue-600 shark:ring-offset-gray-800 focus:ring-2 shark:bg-gray-700 shark:border-gray-600"
                  />

                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                    >
                      Set date
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="setDate"
                      className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                      value={formData.setDate}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <hr className="w-full" />
                <div className="flex flex-row items-center relative right-[11rem] gap-3">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value=""
                    name="checked-radio"
                    className=" w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 shark:focus:ring-blue-600 shark:ring-offset-gray-800 focus:ring-2 shark:bg-gray-700 shark:border-gray-600"
                  />
                  <h6 className="font-all font-semibold gap-3">
                    until the fund = 0
                  </h6>
                </div>
              </div>
            </div>
          </div>

          {/* billing address form */}
          <PromotionBillingAddress />

          {/* biling addressform end here  */}

          <button
            type="submite"
            className=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[1rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out mx-auto flex justify-center"
          >
            Confirm and Make Payment
          </button>
        </form>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
