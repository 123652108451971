// App.js
import React, { useState, useEffect } from 'react';
import Routes from './Routes';
import { WalletProvider } from '../src/pages/Wallet/Context/WalletContext';

import "./style.scss";
import Spinner from './component/Spinner';

function App() {
  const [reloading, setReloading] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = () => {
   
      setReloading(true);
    };

    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // You can simulate a delay or perform any other actions before reloading
    const fetchDataBeforeReload = async () => {
      await new Promise(resolve => setTimeout(resolve, 2000));
      // Perform any other actions before reloading

      // Clear the reloading state after actions are complete
      setReloading(false);
    };

    // Check if the page is reloading
    if (reloading) {
      fetchDataBeforeReload();
    }
  }, [reloading]);

  return (
    <>
      {reloading ? (
        <Spinner />
      ) : (
        // <WalletProvider>
          <Routes />
        // </WalletProvider>
      )}
    </>
  );
}

export default App;
