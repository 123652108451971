// ItemTable.js
import React from "react";
import { Button } from 'flowbite-react';
import { useNavigate } from "react-router-dom";

const ItemTable = ({ data, createItem, selectedType }) => {
  const navigate = useNavigate();

  const handleroute = (jobId) => {
    console.log("Selected Type:", selectedType);
    navigate(`/findproduct/inputs/${jobId}/${selectedType}`);
  };

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full text-left text-sm font-light">
              <thead className="border-b font-medium shark:border-neutral-500">
                <tr>
                  <th scope="col" className="px-6 py-4">

                  </th>
                  <th scope="col" className="px-6 py-4">
                    name
                  </th>
                  <th scope="col" className="px-6 py-4">
                    created at
                  </th>
                  <th scope="col" className="px-6 py-4">
                    updated at
                  </th>
                  <th scope="col" className="px-6 py-4">
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr
                    key={item.id}
                    className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 shark:border-neutral-500 shark:hover:bg-neutral-600"
                  >
                    <td className="whitespace-nowrap px-6 py-4 font-medium">{index + 1}-</td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">{item.name}</td>
                    <td className="whitespace-nowrap px-6 py-4">
                      {new Date(item.createat).toLocaleString()}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      {new Date(item.updatedAt).toLocaleString()}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <button
                        onClick={() => handleroute(item.id)}
                        className="flex select-none items-center text-center gap-2 rounded-lg py-3 px-6 text-cnter align-middle font-sans text-xs font-bold uppercase text-white transition-all bg-[#cb2222]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        type="button"
                      >
                        detail view
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          aria-hidden="true"
                          className="h-4 w-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemTable;
