import React, { useEffect, useState } from "react";
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Loader from '../../../../../component/Loader';
import { useParams } from "react-router-dom";
import {GovProjectDetails} from "../../../../../api/userRegestration/CollectionForm";

export default function OtherProjectDetail() {
    // 
    const { jobId } = useParams();
    const navigate = useNavigate();
    const [jobdetails, setpjobdetails] = useState();
    const [loading, setLoading] = useState(true);
    const Home = () => {
      navigate(`/`);
    };
    // 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    //   
    useEffect(() => {
        GovProjectDetails(jobId)
          .then((response) => {
            console.log("API Response:", response);
            setLoading(true);
            setpjobdetails(response.result);
            setLoading(false);
         
          })
          .catch((error) => {
            console.error("Error:", error);
            setLoading(false);
          });
      }, [jobId]);
  return (
  <>
      <div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh] mb-[7rem] lg:ml-[268px] relative top-[1rem]">
       
       
       {/* {jobdetails && (
      <>
      </>
       )} */}
  
  
  {loading ? ( // Display loader while loading
       <div className="loader">
         <Loader/></div>
     ) : (
        jobdetails && (
         <>
          <>
           <div className="--action breadcrumb--navigate-path ml-8 mt-8">
             <Breadcrumb aria-label="Default breadcrumb example">
               <Breadcrumb.Item icon={HiHome}>
                 <p className="cursor-pointer " onClick={Home}>
                   Home
                 </p>
               </Breadcrumb.Item>

               <Breadcrumb.Item
                 className="cursor-pointer"
                 //   onClick={handleClick}
               >
                 Gov Projects
               </Breadcrumb.Item>

               <Breadcrumb.Item>
                 {jobdetails.projectName}
               </Breadcrumb.Item>
             </Breadcrumb>
           </div>
           <section className="/">
             <div className="container mx-auto px-4">
               <div className="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
                 <div className="lg:col-span-3 lg:row-end-1">
                   <div className="lg:flex lg:items-start">
                     <div className="lg:order-2 lg:ml-5">
                       <div className="max-w-xl overflow-hidden rounded-lg">
                         <img
                           className="h-full w-full max-w-full object-cover"
                           src={jobdetails.mediaUrl}
                           alt=""
                         />
                       </div>
                     </div>
                     <div className="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                       <div className="flex flex-row items-start lg:flex-col">
                         <button
                           type="button"
                           className="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 border-gray-900 text-center"
                         >
                           <img
                             className="h-full w-full object-cover"
                             src={jobdetails.mediaUrl}
                             alt=""
                           />
                         </button>
                         <button
                           type="button"
                           className="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 border-transparent text-center"
                         >
                           <img
                             className="h-full w-full object-cover"
                             src={jobdetails.mediaUrl}
                             alt=""
                           />
                         </button>
                         <button
                           type="button"
                           className="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 border-transparent text-center"
                         >
                           <img
                             className="h-full w-full object-cover"
                             src={jobdetails.mediaUrl}
                             alt=""
                           />
                         </button>
                       </div>
                     </div>
                   </div>
                 </div>
                 <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                   <h1 className="sm: text-2xl font-bold text-gray-900 sm:text-3xl font-all">
                     {jobdetails.projectName} <span> </span>
                   </h1>
                   <div className="mt-5 flex items-center">
                     <div className="flex items-center">
                       <svg
                         className="block h-4 w-4 align-middle text-yellow-500"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                         fill="currentColor"
                       >
                         <path
                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                           className=""
                         />
                       </svg>
                       <svg
                         className="block h-4 w-4 align-middle text-yellow-500"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                         fill="currentColor"
                       >
                         <path
                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                           className=""
                         />
                       </svg>
                       <svg
                         className="block h-4 w-4 align-middle text-yellow-500"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                         fill="currentColor"
                       >
                         <path
                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                           className=""
                         />
                       </svg>
                       <svg
                         className="block h-4 w-4 align-middle text-yellow-500"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                         fill="currentColor"
                       >
                         <path
                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                           className=""
                         />
                       </svg>
                       <svg
                         className="block h-4 w-4 align-middle text-yellow-500"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                         fill="currentColor"
                       >
                         <path
                           d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                           className=""
                         />
                       </svg>
                     </div>
                     <p className="ml-2 text-sm font-medium text-gray-500">
                       1,209 Reviews
                     </p>
                   </div>
                   {/* <h2 className="mt-8 text-base text-gray-900">
                      Skills :
                   </h2> */}
                   {/* <div className="mt-3 flex select-none flex-wrap items-center gap-1">
                 {jobdetails.skills.split(",").map((skill) => (
                   <label key={skill}>
                     <input
                       type="radio"
                       name="type"
                       value={skill.trim()}
                       className="peer sr-only "
                       defaultChecked=""
                     />
                     <p className="bg-black text-white rounded-lg border border-black font-bold font-all text-[10px] p-2 mb-4">
                       {skill.trim()}
                     </p>
                   </label>
                 ))}
               
               </div> */}

            
                   {/*  */}
                   {/* Product rate  */}`

                   <h2 className=" tex-base text-gray-900 font-bold font-all py-4">
                     Company Name :  {" "}
                     <span className=" font-normal">
                       {" "}
                         {jobdetails.companyName}{" "}
                     </span>
                   </h2>

                   <h2 className=" text-base text-gray-900 font-bold font-all">
                     Project budget :  {" "}
                     <span className=" font-normal text-green-600">
                       {" "}
                         {jobdetails.budget}{" "}
                     </span>
                   </h2>
                   {/* minimum order quanity  */}
                   {/* <h2 className="mt-3 text-base text-gray-900 font-bold font-all">
                   Minimum Order Quanity (MOD) :{" "}
                   <span className="italic font-normal"> {jobdetails.moq} </span>
                 </h2> */}
                   {/* <h2 className="mt-3 text-base text-gray-900 font-bold font-all">
                   MQ unit :{" "}
                   <span className="italic font-light"> {jobdetails.mqu} </span>{" "}
                 </h2> */}
                   <h2 className="mt-3 text-base text-gray-900 font-bold font-all">
                     Project Details : <br />{" "}
                     <span className="italic font-normal">
                       {" "}
                       " {jobdetails.details} "
                     </span>
                   </h2>
                
                 </div>
               </div>
             </div>
             <hr className="w-full mt-4 mb-4 border-[#a9a9a9]" />

             <div className="">
               {/* <div className="c-1 flex flex-row gap-[13px] justify-center">
             <span className="bg-[#E6E6E6] flex flex-row  gap-[6px] justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
               {" "}
               <AiOutlineEye /> views
             </span>
             <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
               {" "}
               <MdAdsClick /> clicks
             </span>
             <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
               {" "}
               <AiOutlineHeart /> Likes
             </span>

             <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
               {" "}
               <AiOutlineQuestionCircle /> Inquiries
             </span>
             <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
               {" "}
               <BsGraphUpArrow /> Ranking
             </span>
             <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
               {" "}
               <AiOutlineShareAlt /> share
             </span>
             
           </div> */}
             </div>
             <hr className="w-full mt-4 mb-4 border-[#a9a9a9]" />
<div className="flex flex-row justify-center items-center gap-[5rem]">

             <div className=" border-[1px] mb-4 border-[#D2D2D2] rounded-md flex flex-col gap-3 items-center h-[195px] w-[350px]">
  <h1 className=" text-center font-all text-[19px] font-bold lg:mt-[16px]">
    Reach us {" "}
  </h1>
  <div className=" cursor-pointer  transform hover:scale-105 duration-500 hover:rounded-md ease-in-out border-[1px]  bg-[#F0EAEA] p-[9px] w-[17rem]">
    <h1 className="font-all">
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0}
        viewBox="0 0 512 512"
        className="inline text-[#738482] ml-[14px]"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" />
      </svg>{" "}
      <span
        className=" font-all font-semibold  text-[#616565]"
        style={{ textDecoration: "underline" }}
      >
        +{jobdetails.mobileNumber}
      </span>{" "}
    </h1>
  </div>
  <div className="border-[1px]   bg-[#F0EAEA] p-[9px] w-[17rem] cursor-pointer  transform hover:scale-105 duration-500 hover:rounded-md ease-in-out ">
    <h1 className="font-all ">
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0}
        viewBox="0 0 14 16"
        className="text-[21px] inline text-[#738482] ml-[14px]"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M0 4v8c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H1c-.55 0-1 .45-1 1zm13 0L7 9 1 4h12zM1 5.5l4 3-4 3v-6zM2 12l3.5-3L7 10.5 8.5 9l3.5 3H2zm11-.5l-4-3 4-3v6z"
        />
      </svg>{" "}
      <span
        className=" font-all font-semibold  text-[#4460A0]"
      
      >
       {jobdetails.mail}
      </span>{" "}
    </h1>
  </div>
</div>

<div className="flex flex-col gap-[1rem]  mt-[1.1rem]">
                     <button className=" bg-[#F8F8F8]   border-[1px] border-[#CB2229] shadow-md lg:mx-auto p-[6px] w-[22rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                       leave a meassage
                     </button>
                     <button className=" bg-[#F8F8F8]  border-[1px] border-[#CB2229]  shadow-md lg:mx-auto p-[6px] w-[22rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                       chat now
                     </button>
                   </div>
                   </div>

           </section>
         </>
         </>
       )
     )}



     </div>
  
  
  </>
  )
}
