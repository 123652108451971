import React from "react";
import SliderP from "./SliderP";
// import ProductHomeslider from "../../../ProductCardSlider/ProductHomeslider";
import Bestseller from "./Bestseller";
import { HiArrowLongLeft } from "react-icons/hi2";
import { NavLink } from "react-router-dom";
import ProductForYou from "./ProductForYou";
import Homeslider from "../../../HomePageMainComponent/Homeslider";
import TailwindProductCard from "./TailwindProductCard";
import AddProjectProductsCarousel from "../../AddProject/AddProjectAll/AddProjectProductsCarousel";
// NavLink

export default function Allmain() {
  return (
    <>
      <div className="main--div ">
        <div
          className=" mt-2
     "
        >
          <div className=" flex items-center justify-center h-48 mb-8 ">
            <Homeslider />
          </div>

          <div className="flex flex-row items-center xs:hidden">
            <NavLink to="/">
              <div
                className=" lg:relative  lg:w-[100%]  lg:left-[1rem]    flex flex-row item-center justify-start gap-3 z-10 top-[11rem]"
                style={{ alignItems: "center" }}
              >
                <span className="text-[32px] text-[#4b5563]">
                  <HiArrowLongLeft />
                </span>
                <h1 className="font-semibold  text-[#4b5563] text-[20px]">
                  Go Back to home page
                </h1>
              </div>
            </NavLink>
          </div>

          {/* <TailwindProductCard/> */}

          <div
            className=" mt-[13rem] xs:mt-[5rem] flex items-center justify-center h-max rounded   border-[1px] border-[#D2D2D2] xs:border-none"
            // style={{ border: "1px solid #D2D2D2" }}
          >
            <div className="w-[140vh] xs:w-[40vh]">
              {/* <Bestseller /> */}

              {/* <AddProjectProductsCarousel/> */}
            </div>
          </div>

          <div
            className=" flex items-center justify-center h-max mt-10 xs:mt-[7.5rem] rounded "
            style={{ border: "1px solid #D2D2D2" }}
          >
            <div className="w-[140vh] xs:w-[40vh] xs:mb-[6rem]">
              <ProductForYou />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
