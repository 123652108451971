import axios from "axios";

// all inputs 
export const userInput_products = async (accessToken) => {
    try {
      const response = await axios.get(
        `https://inobackend-production.up.railway.app/api/v1/user/getAllProductsByUserId`,
        {
          headers: {
            Token: accessToken,
          },
        }
      );
  
      if (response.status === 200) {
        return response.data.result;
      } else {
        throw new Error("Failed to fetch store categories");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }; 

  export const userInput_job = async (accessToken) => {
    try {
      const response = await axios.get(
        `https://inobackend-production.up.railway.app/api/v1/user/getAllJobsByUserId`,
        {
          headers: {
            Token: accessToken,
          },
        }
      );
  
      if (response.status === 200) {
        return response.data.result;
      } else {
        throw new Error("Failed to fetch store categories");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }; 

  export const userInput_services = async (accessToken) => {
    try {
      const response = await axios.get(
        `https://inobackend-production.up.railway.app/api/v1/user/getAllServicesByUserId`,
        {
          headers: {
            Token: accessToken,
          },
        }
      );
  
      if (response.status === 200) {
        return response.data.result;
      } else {
        throw new Error("Failed to fetch store categories");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }; 

  export const userInput_post = async (accessToken) => {
    try {
      const response = await axios.get(
        `https://inobackend-production.up.railway.app/api/v1/user/getAllPostsByUserId`,
        {
          headers: {
            Token: accessToken,
          },
        }
      );
  
      if (response.status === 200) {
        return response.data.result;
      } else {
        throw new Error("Failed to fetch store categories");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }; 

  export const userInput_project = async (accessToken) => {
    try {
      const response = await axios.get(
        `https://inobackend-production.up.railway.app/api/v1/user/getAllProjectsByUserId`,
        {
          headers: {
            Token: accessToken,
          },
        }
      );
  
      if (response.status === 200) {
        return response.data.result;
      } else {
        throw new Error("Failed to fetch store categories");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }; 

