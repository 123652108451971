import React, { useState, useRef, useEffect } from 'react';
import { BiArrowFromBottom } from 'react-icons/bi';
import { getAllProductCategories } from '../api/userRegestration/CollectionForm';

const MultiSelectDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const accessToken = localStorage.getItem("token");
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // Ensure accessToken is available before fetching
    if (accessToken) {
      getAllProductCategories(accessToken)
        .then((result) => {
          setOptions(result);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          // Consider displaying an error message to the user
        });
    } else {
      // Handle the case where accessToken is not present
    }
  }, [accessToken]);

  const handleOptionClick = (option) => {
    const isSelected = selectedOptions.includes(option.value);
    const updatedOptions = isSelected
      ? selectedOptions.filter((sel) => sel !== option.value)
      : [...selectedOptions, option.value];
    setSelectedOptions(updatedOptions);
  };

  return (
    <div
      ref={ref}
      className={`relative border rounded-md w-[50%] mx-auto ${selectedOptions.length === 0 && 'text-gray-300 cursor-not-allowed'}`}
      onClick={toggleDropdown}
    >
      <input
        type="hidden"
        name="selectedOptions" // Assume this name is suitable for your form submission
        value={selectedOptions.join(',')} // Store selected values as a comma-separated string
      />
      <div className="flex items-center px-4 py-2">
        <div className="flex rounded-full bg-gray-200 px-2 py-1 mr-2">
          {selectedOptions.map((option) => (
            <span key={option} className="mr-2 rounded-full bg-blue-600 text-white px-2 py-1 text-xs leading-5">
              {option}
            </span>
          ))}
        </div>
        <span className="text-gray-700 text-sm">{selectedOptions.length > 0 ? 'Selected options:' : 'Select options'}</span>
        <div className="ml-2">
          <BiArrowFromBottom className="h-4 w-4 text-gray-700" />
        </div>
      </div>
      {isOpen && (
        <ul className="absolute border rounded-md shadow-md mt-1 w-full z-10 bg-white max-h-60 overflow-y-auto">
          {options.map((option) => (
            <li
              key={option.value}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleOptionClick(option)}
            >
              <span className={`${selectedOptions.includes(option.value) ? 'text-blue-600' : 'text-gray-700'}`}>
                {option.label}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
