import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PromotionFormBody from './PromotionFormBody';

const PromotionHeader = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  const previousPathname = navigate.location?.pathname || '';
  const formTitle = `${pathname === '/findproduct' ? 'Product Form' : `Promotion (${pathname}) Form Body`}`;

  return (
    <>
      <div className="bg-yellow-600 p-5">
        <PromotionFormBody formTitle={formTitle} />
      </div>
    </>
  );
};

export default PromotionHeader;
