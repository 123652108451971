import React, { useEffect, useState } from "react";
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Loader from "../../../component/Loader";
import { deleteItemByType, fetchDetailsByType } from "../../../api/Type";
import ActionButton from "../../../Widgets/ActionButton";
import UpdateInput from "./UpdateInput";
import Inputform from "../../home/ServicesByINO/FindProduct/Inputmain/Inputform";
import AddProjectInput from "../../home/ServicesByINO/AddProject/AddProjectInput/AddProjectInput";
import UpdateFormComponent from "../../../Widgets/Updateablemodule/UpdateFormComponent";

export default function UpdateableDetailView() {
  const { jobId, type } = useParams();
  // const navigate = useNavigate();
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [toggleview, settoggleview] = useState(true);
  const delete_item = async (event) => {
    event.preventDefault();
    const shouldDelete = window.confirm(
      "Are you sure you want to delete the item from inputs?"
    );

    if (shouldDelete) {
      try {
        const deletedItem = await deleteItemByType(type, jobId);
        console.log(deletedItem, "response");
      } catch (error) {
        console.log(error);
      }
    }
  };
  //
  //

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchDetailsByType(type, jobId);
        setDetails(response.result);
        console.log(response);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [jobId, type]);

  return (
    <>
      <div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh] mb-[7rem] lg:ml-[268px] relative top-[1rem]">
        {loading ? (
          <div className="loader">
            <Loader />
          </div>
        ) : (
          details && (
            <>
              {toggleview ? (
                <>
                  <div className="___view_? ">
                    <ActionButton
                      remove={delete_item}
                      settoggleview={settoggleview}
                    />

                    <section className="/">
                      <div className="container mx-auto px-4">
                        <div className="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
                          <div className="lg:col-span-3 lg:row-end-1">
                            <div className="lg:flex lg:items-start">
                              <div className="lg:order-2 lg:ml-5">
                                <div className="max-w-xl overflow-hidden rounded-lg">
                                  <img
                                    className="h-full w-full max-w-full object-cover"
                                    src={details.mediaUrl}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                            <h1 className="sm: text-2xl font-bold text-gray-900 sm:text-3xl font-all">
                              {/* {details.productName}  */}
                              {/* {type === "PRODUCT" && details.productName}
                        {type === "SERVICE" && details.serviceName}

                        {type === "JOB" && details.title}
                        {type === "POST" && details.postTitle} */}

                              {/*  */}
                              {type === "PRODUCT"
                                ? details.productName
                                : type === "SERVICE"
                                ? details.serviceName
                                : type === "JOB"
                                ? details.title
                                : type === "POST"
                                ? details.postTitle
                                : type === "PROJECT"
                                ? details.projectName
                                : ""}

                              {/*  */}

                              <span> </span>
                            </h1>
                            <div className="mt-5 flex items-center">
                              <div className="flex items-center">
                                <svg
                                  className="block h-4 w-4 align-middle text-yellow-500"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                    className=""
                                  />
                                </svg>
                                <svg
                                  className="block h-4 w-4 align-middle text-yellow-500"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                    className=""
                                  />
                                </svg>
                                <svg
                                  className="block h-4 w-4 align-middle text-yellow-500"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                    className=""
                                  />
                                </svg>
                                <svg
                                  className="block h-4 w-4 align-middle text-yellow-500"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                    className=""
                                  />
                                </svg>
                                <svg
                                  className="block h-4 w-4 align-middle text-yellow-500"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                    className=""
                                  />
                                </svg>
                              </div>
                              <p className="ml-2 text-sm font-medium text-gray-500">
                                1,209 Reviews
                              </p>
                            </div>
                            <h2 className="mt-8 text-base text-gray-900">
                              {type === "PRODUCT"
                                ? "Categories"
                                : type === "SERVICE"
                                ? "Categories"
                                : type === "JOB"
                                ? "Skills"
                                : type === "PROJECT"
                                ? "Company "
                                : ""}{" "}
                              :
                            </h2>
                            <div className="mt-3 flex select-none flex-wrap items-center gap-1">
                              <label>
                                <input
                                  type="radio"
                                  name="type"
                                  className="peer sr-only"
                                  defaultChecked=""
                                />
                                <p className="peer-checked:bg-black peer-checked:text-white rounded-lg border border-black px-6 py-2 font-bold">
                                  {type === "PRODUCT"
                                    ? details.categorie
                                    : type === "SERVICE"
                                    ? details.categorie
                                    : type === "JOB"
                                    ? details.skills
                                    : type === "PROJECT"
                                    ? details.companyName
                                    : ""}
                                </p>
                              </label>
                            </div>
                            {/*  */}
                            {/* Product rate  */}
                            <h2 className="mt-8 text-base text-gray-900 font-bold font-all">
                              {type === "PRODUCT"
                                ? "Product Price"
                                : type === "SERVICE"
                                ? "Service Rate"
                                : type === "JOB"
                                ? "salary"
                                : type === "PROJECT"
                                ? "Budget"
                                : ""}{" "}
                              :
                              <span className="italic font-normal">
                                {type === "PRODUCT"
                                  ? details.amount
                                  : type === "SERVICE"
                                  ? details.rate
                                  : type === "JOB"
                                  ? details.salary
                                  : type === "PROJECT"
                                  ? details.budget
                                  : ""}
                              </span>
                            </h2>
                            {/* minimum order quanity  */}

                            <div
                              className={
                                type === "PRODUCT" ? "block" : "hidden"
                              }
                            >
                              <h2 className="mt-3 text-base text-gray-900 font-bold font-all">
                                {type === "PRODUCT"
                                  ? "MOQ"
                                  : type === "SERVICE"
                                  ? ""
                                  : type === "JOB"
                                  ? ""
                                  : type === "PROJECT"
                                  ? "/*  */"
                                  : ""}{" "}
                                :
                                <span className="italic font-normal">
                                  {" "}
                                  {details.moq}{" "}
                                </span>
                              </h2>
                              <h2 className="mt-3 text-base text-gray-900 font-bold font-all">
                                MQ unit :{" "}
                                <span className="italic font-light">
                                  {" "}
                                  {details.mqu}{" "}
                                </span>{" "}
                              </h2>
                            </div>

                            <div
                              className={
                                type === "JOB" && "PROJECT" ? "block" : "hidden"
                              }
                            >
                              <h2 className="mt-3 text-base text-gray-900 font-bold font-all">
                                {type == "JOB"
                                  ? "Expirience"
                                  : type === "PROJECT"
                                  ? "mail"
                                  : ""}{" "}
                                :
                                <span className=" font-normal">
                                  {type === "JOB"
                                    ? details.expirience
                                    : type === "PROJECT"
                                    ? details.mail
                                    : ""}{" "}
                                </span>
                              </h2>
                            </div>
                            <h2 className="mt-8 text-base text-gray-900 font-bold font-all">
                              Details : <br />{" "}
                              <span className="italic font-normal">
                                {" "}
                                {details.details}
                              </span>
                            </h2>
                          </div>
                        </div>
                      </div>
                      <hr className="w-full mt-4 mb-4 border-[#a9a9a9]" />
                    </section>
                  </div>
                </>
              ) : (
                <>
                  <UpdateFormComponent jobId={jobId}  details={details} />
                </>
              )}
            </>
          )
        )}
      </div>
    </>
  );
}
