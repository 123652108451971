// Import necessary modules and dependencies
import React, { createContext, useContext, useReducer, useState } from "react";
import { AuthContext } from "./AuthContext";
import { collection, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
// 
// Create ChatContext
export const ChatContext = createContext();

// ChatContextProvider Component
export const ChatContextProvider = ({ children }) => {
  // Access currentUser from AuthContext
  const { currentUser } = useContext(AuthContext);

  // State variables
  const [receiverId, setReceiverId] = useState(null);
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState("");

// handle search 

  const handleSearch = async () => {
    const q = query(
      collection(db, "users"),
      where("uid", "==", receiverId)
    );

// handle select 


    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        console.log((doc.data()));
        console.warn("user state updated with ",((doc.data())));
        setUser(doc.data());
      });
    } catch (err) {
      setErr(true);
    }
  };

  const handleSelect = async () => {

    //check whether the group(chats in firestore) exists, if not create
    const combinedId =
      currentUser.uid > user.uid
        ? currentUser.uid + user.uid
        : user.uid + currentUser.uid;
    try {
      const res = await getDoc(doc(db, "chats", combinedId));

      if (!res.exists()) {
        //create a chat in chats collection
        await setDoc(doc(db, "chats", combinedId), { messages: [] });

        //create user chats
        await updateDoc(doc(db, "userChats", currentUser.uid), {
          [combinedId + ".userInfo"]: {
            uid: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", user.uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });
      }
    } catch (err) {}

    setUser(null);
    setUsername("");
  };


  // Initial State for chatReducer
  const INITIAL_STATE = {
    chatId: "null",
    user: {},
  };

  // chatReducer function
  const chatReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_USER":
        return {
          // @ the user work as receiver && the current user cycled with sender
          user: action.payload,
          chatId:
            currentUser.uid > action.payload.uid
              ? currentUser.uid + action.payload.uid
              : action.payload.uid + currentUser.uid,
        };
      default:
        return state;
    }
  };

  // useReducer hook for chatReducer
  const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);

  // Return ChatContext Provider with values
  return (
    <ChatContext.Provider
      value={{
        data: state,
        dispatch,
        receiverId,
        setReceiverId,
        username,
        setUsername,
        user,
        setUser,
        handleSearch,
        handleSelect

      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
