import React, { useContext } from 'react'
import {signOut} from "firebase/auth"
import { auth } from '../../../firebase'
import { AuthContext } from "../../../Context/AuthContext";


const Navbar = () => {
  const {currentUser} = useContext(AuthContext)

  return (
   
    // this component is used to show the current register user sender information 
    <div className='navbar'>
      <div className="user">
        <img src={currentUser.photoURL} alt="" />
        <span className='text-yellow-500'>{currentUser.displayName}</span>
    
      </div>
    </div>
  )
}

export default Navbar